import React, { useState } from 'react';
import './Footer.css';
import logo from './halal.png';
import conditionCGU from './condition-CGU.pdf';
import politiqueCookies from './politique-cookies.pdf';
import conditionCGV from './condition-CGV.pdf';
const Footer = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const encodedAddress = encodeURIComponent('24 Bd Sylvain Dumon, 47000 Agen, France');
  const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`;

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <footer className="footer">
      <div className="footer-content">
        <img src={logo} alt="Logo" className="footer-logo" />
        <div className="contact-info">
          <h3>Contactez nous :</h3>
          <p><strong>Téléphone:</strong> 05 53 98 47 92</p>
          <p><strong>Email:</strong>shahidur1992@yahoo.com</p>
          <p>
            <strong>Adresse:</strong> 
            <a href={googleMapsUrl} target="_blank" rel="noopener noreferrer" className="address-link">
              24 Bd Sylvain Dumon, 47000 Agen, France
            </a>
          </p>
          <p><strong>Horaire d'ouverture:</strong> Lundi - Dimanche 11:00-23:00</p>
        </div>
      </div>
      <hr />
      {/* Conditions générales d'utilisation */}
      <div className="terms">
        <h4 onClick={toggleModal} className="terms-title">Mentions légales</h4>
        {isModalOpen && (
          <div className="terms-modal">
            <div className="modal-content">
              <span className="close-button" onClick={toggleModal}>&times;</span>
              <ul>
                <li><a href={conditionCGU} target="_blank" rel="noopener noreferrer">Conditions CGU</a></li>
                <li><a href={conditionCGV} target="_blank" rel="noopener noreferrer">Conditions CGV</a></li>
                <li><a href={politiqueCookies} target="_blank" rel="noopener noreferrer">Politique des cookies</a></li>
              </ul>
            </div>
          </div>
        )}
      </div>
      <p className="copyright">© 2024 All rights reserved</p>
    </footer>
  );
}

export default Footer;
